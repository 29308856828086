import {
  addDoc,
  collection,
  doc,
  getDoc,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import axios from "./axios";
import { db } from "../db/firebase";

export const getCardknoxIfieldKey = async () => {
  try {
    const response = await axios.get("/api/payment/config");
    return response.data.data.ifieldKey;
  } catch (error) {
    throw error;
  }
};

export const storeTransaction = async (
  response,
  senderDetails,
  camperId,
  accountId
) => {
  try {
    const transactionData = {
      ...response,
      ...senderDetails,
      timeStamp: serverTimestamp(),
    };

    const userDocRef = doc(db, "users", camperId);

    await addDoc(collection(userDocRef, "transactions"), transactionData);

    if (response.xResult === "A") {
      const accountDocRef = doc(userDocRef, "accounts", accountId);

      const accountDocSnap = await getDoc(accountDocRef);

      if (accountDocSnap.exists()) {
        const accountData = accountDocSnap.data();

        const currentBalance = parseFloat(accountData.balance || 0);
        const newBalance =
          currentBalance + parseFloat(senderDetails.amount || 0);

        const transactionDetails = {
          amount: senderDetails.amount,
          type: "deposit",
          method: "Pay with Cardknox",
          timestamp: new Date().toISOString(),
        };

        const updatedBalanceHistory = [
          ...accountData.balanceHistory,
          transactionDetails,
        ];

        await updateDoc(accountDocRef, {
          balance: newBalance,
          balanceHistory: updatedBalanceHistory,
        });
      } else {
        throw new Error(`Account with ID ${accountId} not found.`);
      }
    }
  } catch (error) {
    throw error;
  }
};
