import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSenderDetails } from "../../redux/accounts/accountSlice";
import { predefinedAmounts } from "../../data";

const AmountSelector = () => {
  const dispatch = useDispatch();
  const { senderDetails } = useSelector((state) => state.account);

  const handleAmountClick = (amount) => {
    dispatch(
      setSenderDetails({
        amount: parseInt(amount),
        applicationFee: 2,
        totalPay: parseInt(amount) + 2,
      })
    );
  };

  const handleAmountChange = (e) => {
    const amt = e.target.value;
    if (!amt) {
      dispatch(
        setSenderDetails({
          amount: "",
          applicationFee: 0,
          totalPay: 0,
        })
      );
      return;
    }

    dispatch(
      setSenderDetails({
        amount: parseInt(amt),
        applicationFee: 2,
        totalPay: parseInt(amt) + 2,
      })
    );
  };

  return (
    <div
      className="flex flex-col md:flex-row gap-[10px] md:gap-[29px] justify-evenly w-full"
      style={{
        fontWeight: "bold",
        fontSize: "25px",
        color: "#000000",
        lineHeight: "37.5px",
      }}
    >
      <div className="grid grid-cols-2 sm:grid-cols-4 gap-[9px] max-w-[100%] sm:min-w-[370px]">
        {predefinedAmounts.map((amount, index) => (
          <div className="relative flex items-center justify-center" key={index}>
            <button
              key={amount}
              onClick={() => handleAmountClick(amount)}
              className={`h-[66px] w-full rounded-[5px] bg-white relative 
              ${
                senderDetails.amount === amount
                  ? "border-[2px] border-[#E37330]"
                  : "hover:text-primary"
              }
                `}
            >
              &#36;{amount}
            </button>

            {amount === 40 && (
              <span
                className="absolute bottom-0 w-full h-[14px] text-[8px] leading-[12px] font-medium text-white flex items-center justify-center rounded-b-[5px] uppercase"
                style={{
                  background:
                    "linear-gradient(90deg, #E1702D 0%, #F8A45F 100%)",
                }}
              >
                Most poplar
              </span>
            )}
          </div>
        ))}
      </div>

      <div className="flex-grow relative flex items-center h-[76px]">
        <input
          type="number"
          value={senderDetails.amount}
          onChange={handleAmountChange}
          className="w-full h-full pl-10 rounded-[5px] focus:outline-none number-input self-end text-right pr-[60px]"
          onWheel={(e) => e.target.blur()} // disable scrolling when user scrolls and changed number input
        />
        <span className="absolute left-4">&#36;</span>
        <span className="absolute right-4">
          .00
          <span className="text-[15px] relative">
            <span className="bottom-0 absolute">*</span>
          </span>
        </span>
      </div>
    </div>
  );
};

export default AmountSelector;
