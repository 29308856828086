import React from "react";
import { Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import GooglePayCheckout from "./component/cardknox/GooglePayCheckout";

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/google-pay" element={<GooglePayCheckout />} />
    </Routes>
  );
};

export default App;
