import React, { useState, useRef, useEffect } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

const SelectField = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    props.value
      ? props.items.find((item) => item.value === props.value)?.label
      : null
  );
  const selectRef = useRef(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (!props.value) {
      setSelectedOption(null);
    } else {
      const foundItem = props.items.find((item) => item.value === props.value);
      setSelectedOption(foundItem ? foundItem.label : null);
    }
  }, [props.value, props.items]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        selectRef.current &&
        !selectRef.current.contains(event.target) &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("scroll", handleClickOutside, true);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("scroll", handleClickOutside, true);
    };
  }, []);

  const handleToggle = (e) => {
    if (props.disabled) return;
    setIsOpen(!isOpen);
  };

  const onOptionClicked = (item) => {
    if (props.disabled) return;
    setSelectedOption(item.label);
    setIsOpen(false);
    props.onChange(props.id, item.value);
  };

  return (
    <div
      className={`flex flex-col space-y-[4px] ${
        props.disabled ? "item-disabled" : ""
      }`}
      ref={selectRef}
    >
      <label
        htmlFor={props.id}
        className={`${!props.notRequired && "required-input"}`}
        style={{
          fontFamily: "Poppins",
          fontWeight: 500,
          color: "var(--color-primary)",
          fontSize: "10px",
          lineHeight: "15px",
          paddingLeft: "20px",
        }}
      >
        {props.label}
      </label>
      <div className="relative inline-flex w-full bg-inputBackground rounded-lg h-[33px]">
        <div
          className={`w-full px-4 py-2 flex items-center text-sm capitalize ${
            props.disabled ? "cursor-not-allowed" : "cursor-pointer"
          }`}
          onClick={handleToggle}
          style={{ color: selectedOption ? "#000000" : "#A0AEC0" }}
        >
          {selectedOption || props.placeholder || "Select"}
        </div>
        <div
          className={`absolute right-3 top-1/2 transform -translate-y-1/2 h-6 w-6 flex justify-center items-center ${
            props.disabled ? "cursor-not-allowed" : "cursor-pointer"
          }`}
          onClick={handleToggle}
        >
          {isOpen && !props.disabled ? (
            <IoIosArrowUp style={{ color: "var(--color-primary)" }} />
          ) : (
            <IoIosArrowDown
              style={{
                color: props.disabled ? "var(--color-disabled)" : "#000000",
              }}
            />
          )}
        </div>
      </div>

      {isOpen && !props.disabled && (
        <div
          ref={dropdownRef}
          className="fixed z-50 left-0 mt-1 w-full origin-top-right rounded-md bg-inputBackground max-h-60 overflow-y-auto custom-scrollbar shadow-lg"
          style={{
            top: selectRef.current
              ? selectRef.current.getBoundingClientRect().bottom
              : "auto",
            left: selectRef.current
              ? selectRef.current.getBoundingClientRect().left
              : "auto",
            width: selectRef.current
              ? selectRef.current.getBoundingClientRect().width
              : "auto",
          }}
        >
          {props.items.map((item) => (
            <button
              type="button"
              onClick={() => onOptionClicked(item)}
              key={item.value}
              className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-primary hover:text-white focus:outline-none capitalize"
            >
              {item.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default SelectField;
