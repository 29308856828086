import React, { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import tablet from "../assets/images/tablet.webp";
import mobile from "../assets/images/mobile.webp";
import HomeText from "../component/common/HomeText";
import PoweredBy from "../component/common/PoweredBy";
import Button from "../component/common/Button";
import Modal from "../component/common/Modal";
import AddCash from "../component/modalContent/AddCash";
import { useDispatch } from "react-redux";
import { fetchCamps, resetAccount } from "../redux/accounts/accountSlice";
import { getCardknoxIfieldKey } from "../redux/cards/cardSlice";

const HomePage = () => {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCamps());
    dispatch(getCardknoxIfieldKey());
  }, [dispatch]);

  const handleCloseModal = () => {
    dispatch(resetAccount());
    setShowModal(false);
  };

  return (
    <Layout>
      <div className="w-full h-[calc(100vh-62px)] lg:h-[calc(100vh-75px)] flex items-center justify-center">
        {/* Desktop View */}
        <div className="relative w-[90%] h-[100%] hidden lg:flex overflow-y-auto hide-scrollbar">
          {/* right content */}
          <div className="absolute right-0 w-[70%] h-full">
            <img
              src={tablet}
              alt="tablet"
              className="w-full min-h-[600px] h-full object-contain"
            />
          </div>

          {/* left content */}
          <div
            className="absolute left-0 w-[70%] h-full min-h-[700px] p-5 flex flex-col justify-evenly gap-12"
            style={{
              background:
                "linear-gradient(270deg, rgba(255, 255, 255, 0) -5.71%, #FFFFFF 30.97%)",
            }}
          >
            <HomeText />

            <Button normalButton={true} onClick={() => setShowModal(true)}>
              add
            </Button>

            <PoweredBy />
          </div>
        </div>

        {/* Mobile View */}
        <div className="block lg:hidden w-[100%] h-[100%] overflow-y-auto">
          <div className="w-[100%] h-[40%] min-h-[310px] py-[27px] flex justify-center items-center px-2">
            <HomeText />
          </div>

          <div className="w-[100%] h-[60%] min-h-[500px] flex items-center justify-center relative">
            <img
              src={mobile}
              alt="mobile image"
              className="h-full min-h-[500px] object-contain"
            />

            <div
              className="absolute bottom-0 w-full h-[45%] flex justify-center"
              style={{
                background:
                  "linear-gradient(rgba(255, 255, 255, 0) 1.38%, #FFFFFF 31.07%)",
              }}
            >
              <div className="flex flex-col items-center justify-evenly">
                <Button normalButton={true} onClick={() => setShowModal(true)}>
                  add
                </Button>
                <PoweredBy />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal isOpen={showModal} onClose={handleCloseModal}>
        <AddCash onClose={handleCloseModal} />
      </Modal>
    </Layout>
  );
};

export default HomePage;
