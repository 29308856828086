import {
  Body,
  Container,
  Column,
  Head,
  Html,
  Img,
  Link,
  Preview,
  Row,
  Section,
  Text,
} from "@react-email/components";
import * as React from "react";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const Email = ({
  name,
  camperName,
  amount,
  method = "card",
  date,
  managerName,
  managerEmail,
  managerPhoneNo,
}) => (
  <Html>
    <Head />
    <Preview>Payment Receipt</Preview>
    <Body style={main}>
      <Container style={container}>
        <Section>
          <Img
            style={sectionLogo}
            src={`${baseUrl}/emailImages/logo.png`}
            width="62px"
            height="62px"
            alt="CAMP POS"
          />
        </Section>

        <Section style={paragraphContent}>
          <Text style={{ ...paragraph, textTransform: "capitalize" }}>
            Hello {name},
          </Text>
          <Text style={paragraph}>
            Thank you for adding funds to{" "}
            <span style={{ textTransform: "capitalize" }}>{camperName}</span>’s
            account.
          </Text>
          <Text style={paragraph}>
            The funds are instantly available for use. As soon as your
            transaction is processed, the amount will be credited to{" "}
            {camperName}'s account and ready for immediate use. If for any
            reason the camp is using our offline POS system, please allow up to
            24 hours for the funds to be reflected.
          </Text>

          <Text style={{ ...paragraph, marginBottom: 0 }}>
            Transaction Summary:
          </Text>
          <table
            style={{
              ...paragraph,
              paddingLeft: "16px",
              width: "100%",
            }}
            role="presentation"
          >
            <tr>
              <td style={{ width: "10px", verticalAlign: "top" }}>•</td>
              <td>
                Amount Added: <b style={boldContent}>${amount || 0}</b>
              </td>
            </tr>
            <tr>
              <td style={{ width: "10px", verticalAlign: "top" }}>•</td>
              <td>
                Transaction Method: <b style={boldContent}>{method}</b>
              </td>
            </tr>
            <tr>
              <td style={{ width: "10px", verticalAlign: "top" }}>•</td>
              <td>
                Date of Transaction: <b style={boldContent}>{date}</b>
              </td>
            </tr>
          </table>

          <Text style={paragraph}>
            If you have any questions, please feel free to contact Canteen:
          </Text>

          <Text
            style={{
              ...paragraph,
              margin: 0,
            }}
          >
            {managerName || "abc"}
          </Text>
          <Text
            style={{
              ...paragraph,
              margin: 0,
            }}
          >
            Camp Canteen Email:{" "}
            <b style={boldContent}>{managerEmail || "abc@gmail.com"}</b>
          </Text>
          <Text
            style={{
              ...paragraph,
              margin: 0,
            }}
          >
            Camp Canteen Phone:{" "}
            <b style={boldContent}>{managerPhoneNo || "+1xxxxxxxxx"}</b>
          </Text>

          <Text style={{ ...paragraph, marginBottom: 0 }}>Thank you,</Text>
          <Text style={{ ...boldContent, marginTop: 0 }}>The Campos Team</Text>
        </Section>

        <Section style={containerContact}>
          <Row
            style={{
              margin: "auto",
              width: "125px",
              marginTop: "100px",
            }}
          >
            <Column>
              <Text style={grayText}>powered by:</Text>
            </Column>
            <Column>
              <Link href="https://osfy.io/">
                <Img
                  width="50.22px"
                  height="34px"
                  src={`${baseUrl}/emailImages/osfy.png`}
                  alt="osfy logo"
                />
              </Link>
            </Column>
          </Row>
        </Section>
      </Container>
    </Body>
  </Html>
);

export default Email;

const main = {
  backgroundColor: "#dbddde",
  fontFamily:
    '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif',
};

const sectionLogo = {
  marginLeft: "40px",
  marginTop: "40px",
};

const container = {
  margin: "30px auto",
  backgroundColor: "#fff",
  borderRadius: 5,
  overflow: "hidden",
};

const containerContact = {
  background:
    "linear-gradient(180deg, rgba(217, 217, 217, 0) 0%, rgba(25, 97, 253, 0.3) 157.6%)",
  width: "100%",
  borderRadius: "5px",
  overflow: "hidden",
  paddingLeft: "20px",
  height: "188px",
};

const paragraphContent = {
  padding: "0 40px",
};

const paragraph = {
  fontSize: "14px",
  lineHeight: "22px",
  color: "#3c4043",
};

const boldContent = {
  ...paragraph,
  fontWeight: "600",
};

const grayText = {
  fontSize: "10px",
  lineHeight: "15px",
  color: "#525252",
};
