import React, { useState, useRef, useEffect } from "react";
import { FaSearch } from "react-icons/fa";

const SearchResults = ({
  id,
  label,
  items,
  value = "",
  onInputChange,
  onItemSelect,
  disabled = false,
  notRequired = false,
  placeholder,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState(
    value ? items.find((item) => item.value === value)?.label : ""
  );
  const selectRef = useRef(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (!value) setSearchTerm("");
  }, [value]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        selectRef.current &&
        !selectRef.current.contains(event.target) &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("scroll", handleClickOutside, true);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("scroll", handleClickOutside, true);
    };
  }, []);

  const handleChange = (e) => {
    const { id, value } = e.target;
    onInputChange(id, value);
    setSearchTerm(value);
    setIsOpen(true);
  };

  const onOptionClicked = (item) => {
    if (disabled) return;
    setSearchTerm(item.label);
    onItemSelect(id, item.value);
    setIsOpen(false);
  };

  return (
    <div
      className={`flex flex-col space-y-[4px] ${
        disabled ? "item-disabled" : ""
      }`}
      ref={selectRef}
    >
      <label
        htmlFor={id}
        className={`${!notRequired ? "required-input" : ""}`}
        style={{
          fontFamily: "Poppins",
          fontWeight: 500,
          color: "var(--color-primary)",
          fontSize: "10px",
          lineHeight: "15px",
          paddingLeft: "20px",
        }}
      >
        {label}
      </label>
      <div className="relative inline-flex w-full bg-inputBackground rounded-lg h-[33px]">
        <input
          id={id}
          className={`w-full p-4 pl-[20px] flex items-center text-sm bg-inputBackground rounded-lg border border-inputBackground focus:outline-none focus:border-primary focus:ring-primary text-[14px] font-normal capitalize ${
            disabled ? "cursor-not-allowed" : "cursor-text"
          }`}
          type="text"
          onChange={handleChange}
          placeholder={placeholder}
          value={searchTerm}
          disabled={disabled}
        />
        <div
          className={`absolute right-3 top-1/2 transform -translate-y-1/2 h-4 w-4 flex justify-center items-center`}
        >
          <FaSearch style={{ color: isOpen ? "var(--color-primary)" : "#000000" }} />
        </div>
      </div>

      {isOpen && !disabled && (
        <div
          ref={dropdownRef}
          className="fixed z-50 left-0 mt-1 w-full origin-top-right rounded-md bg-inputBackground max-h-60 overflow-y-auto custom-scrollbar shadow-lg"
          style={{
            top: selectRef.current
              ? selectRef.current.getBoundingClientRect().bottom
              : "auto",
            left: selectRef.current
              ? selectRef.current.getBoundingClientRect().left
              : "auto",
            width: selectRef.current
              ? selectRef.current.getBoundingClientRect().width
              : "auto",
          }}
        >
          {items?.map((item) => (
            <button
              type="button"
              onClick={() => onOptionClicked(item)}
              key={item.value}
              className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-primary hover:text-white focus:outline-none capitalize"
            >
              {item.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default SearchResults;
