/* global iStatus,  GPButtonSizeMode, GPButtonType, GPBillingAddressFormat, GPEnvironment, ckGooglePay, logDebug, paymentToken, isDebugEnv, roundToNumber, GPButtonColor */
// above global variable is used to avoid eslint  errors
import React, { useEffect } from "react";
import "./cardknox.css";
import { useSelector } from "react-redux";

const GooglePayCheckout = () => {
  const { senderDetails } = useSelector((state) => state.account);

  useEffect(() => {
    initializeGooglePay();
  }, []);

  const initializeGooglePay = () => {
    // Check if in debug mode
    if (/[?&](is)?debug/i.test(window.location.search)) {
      window.setDebugEnv(true);
    }

    if (typeof ckGooglePay === "undefined") {
      console.error("ckGooglePay is not available.");
      return;
    }

    ckGooglePay.enableGooglePay({ amountField: "amount" });
  };

  const showHide = (elem, toShow) => {
    const element =
      typeof elem === "string" ? document.getElementById(elem) : elem;

    if (element) {
      toShow
        ? element.classList.remove("hidden")
        : element.classList.add("hidden");
    }
  };
  const getAmount = () => {
    return roundToNumber(senderDetails ? senderDetails.totalPay : "0", 2);
  };

  const setGPPayload = (value) => {
    const gpTxt = document.getElementById("gp-payload");
    if (gpTxt) {
      gpTxt.value = value;
      showHide(gpTxt, !!value);
    }
  };

  const gpRequest = {
    merchantInfo: {
      merchantName: "Camp POS",
    },
    buttonOptions: {
      buttonSizeMode: GPButtonSizeMode.static,
      buttonType: GPButtonType.plain,
      buttonColor: GPButtonColor.black,
    },
    billingParams: {
      // phoneNumberRequired: true,
      emailRequired: true,
      billingAddressRequired: true,
      billingAddressFormat: GPBillingAddressFormat.full,
    },
    shippingParams: {
      phoneNumberRequired: true,
      emailRequired: true,
      onGetShippingCosts: function (shippingData) {
        logDebug({
          label: "onGetShippingCosts",
          data: shippingData,
        });
        return {
          "shipping-001": "0.00",
          "shipping-002": "1.99",
          "shipping-003": "10.00",
        };
      },
      onGetShippingOptions: function (shippingData) {
        logDebug({
          label: "onGetShippingOptions",
          data: shippingData,
        });
        let selectedOptionid = "shipping-001";
        if (
          shippingData &&
          shippingData.shippingOptionData &&
          shippingData.shippingOptionData.id !== "shipping_option_unselected"
        ) {
          selectedOptionid = shippingData.shippingOptionData.id;
        }
        return {
          defaultSelectedOptionId: selectedOptionid,
          shippingOptions: [
            {
              id: "shipping-001",
              label: "Free: Standard shipping",
              description: "Free Shipping delivered in 5 business days.",
            },
            {
              id: "shipping-002",
              label: "$1.99: Standard shipping",
              description: "Standard shipping delivered in 3 business days.",
            },
            {
              id: "shipping-003",
              label: "$10: Express shipping",
              description: "Express shipping delivered in 1 business day.",
            },
          ],
        };
      },
    },
    onGetTransactionInfo: function () {
      let amt = getAmount();
      return {
        displayItems: [
          {
            label: "Subtotal",
            type: "SUBTOTAL",
            price: amt.toString(),
          },
          {
            label: "Tax",
            type: "TAX",
            price: (0.1 * amt).toString(),
          },
        ],
        countryCode: "US",
        currencyCode: "USD",
        totalPriceStatus: "FINAL",
        totalPrice: (1.1 * amt).toString(),
        totalPriceLabel: "Total",
        // countryCode: "IN",
        // currencyCode: "INR",
        // totalPriceStatus: "FINAL",
        // totalPrice: (1.18 + amt).toString(),
        // totalPriceLabel: "Total",
      };
    },
    onBeforeProcessPayment: function () {
      return new Promise(function (resolve, reject) {
        try {
          //Do some validation here
          resolve(iStatus.success);
        } catch (err) {
          reject(err);
        }
      });
    },
    onProcessPayment: function (paymentResponse) {
      return new Promise(function (resolve, reject) {
        try {
          // show returned data in developer console for debugging
          console.log("paymentResponse", JSON.stringify(paymentResponse));
          paymentToken =
            paymentResponse.paymentData.paymentMethodData.tokenizationData
              .token;
          console.log("paymentToken", paymentToken);
          const amt =
            (paymentResponse &&
              paymentResponse.transactionInfo &&
              paymentResponse.transactionInfo.totalPrice) ||
            0;
          try {
            if (amt <= 0) {
              throw "Payment is not authorized. Invalid amount. Amount must be greater than 0";
            }
            setGPPayload(JSON.stringify(paymentResponse, null, 2));
            resolve({ status: iStatus.success });
          } catch (err) {
            const emsg = JSON.stringify(err);
            console.error(emsg);
            setGPPayload("");
            setTimeout(function () {
              alert(emsg);
            }, 500);
            reject({ error: err });
          }
        } catch (err) {
          setGPPayload("");
          reject(err);
        }
      });
    },
    onPaymentCanceled: function (respCanceled) {
      setTimeout(function () {
        alert("Payment was canceled");
      }, 500);
    },
    handleResponse: function (resp) {
      const respObj = JSON.parse(resp);
      if (respObj) {
        if (respObj.xError) {
          setTimeout(function () {
            alert(`There was a problem with your order (${respObj.xRefNum})!`);
          }, 500);
        } else
          setTimeout(function () {
            alert(`Thank you for your order (${respObj.xRefNum})!`);
          }, 500);
      }
    },
    getGPEnvironment: function () {
      if (/[?&]prod/.test(window.location.search)) {
        return GPEnvironment.production;
      }
      return GPEnvironment.test;
    },
    initGP: function () {
      return {
        merchantInfo: this.merchantInfo,
        buttonOptions: this.buttonOptions,
        environment: this.getGPEnvironment(),
        billingParameters: this.billingParams,
        // shippingParameters: {
        //   emailRequired: this.shippingParams.emailRequired,
        //   onGetShippingCosts: "gpRequest.shippingParams.onGetShippingCosts",
        //   onGetShippingOptions: "gpRequest.shippingParams.onGetShippingOptions",
        // },
        onGetTransactionInfo: "gpRequest.onGetTransactionInfo",
        onBeforeProcessPayment: "gpRequest.onBeforeProcessPayment",
        onProcessPayment: "gpRequest.onProcessPayment",
        onPaymentCanceled: "gpRequest.onPaymentCanceled",
        onGPButtonLoaded: "gpRequest.gpButtonLoaded",
        isDebug: isDebugEnv,
      };
    },
    gpButtonLoaded: function (resp) {
      if (!resp) return;
      if (resp.status === iStatus.success) {
        showHide("divGpay", true);
        showHide("lbGPPayload", true);
      } else if (resp.reason) {
        alert(resp.reason);
      }
    },
  };

  useEffect(() => {
    window.gpRequest = gpRequest;
  }, []);

  return (
    <iframe
      id="igp"
      className="gp"
      data-ifields-id="igp"
      data-ifields-oninit="gpRequest.initGP"
      src="https://cdn.cardknox.com/ifields/2.15.2409.2601/igp.htm"
      allowpaymentrequest="true"
      sandbox="allow-popups allow-modals allow-scripts allow-same-origin allow-forms allow-popups-to-escape-sandbox allow-top-navigation"
      title="GPay checkout page"
    />
  );
};

export default GooglePayCheckout;
