import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AccountStatus } from "../../data";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../db/firebase";

export const fetchCamps = createAsyncThunk(
  "fetchCamps",
  async (_, { rejectWithValue }) => {
    try {
      const usersCollection = collection(db, "users");
      const querySnapshot = await getDocs(usersCollection);

      const camps = querySnapshot.docs.map((doc) => ({
        label: doc.data().subTitle || "",
        value: doc.id,
      }));

      return camps;
    } catch (error) {
      return rejectWithValue(error.message || "Failed to fetch Camps");
    }
  }
);

export const fetchBunks = createAsyncThunk(
  "fetchBunks",
  async (selectedCampId, { rejectWithValue }) => {
    try {
      const userDocRef = doc(db, "users", selectedCampId);
      const userDocSnap = await getDoc(userDocRef);

      if (!userDocSnap) {
        throw new Error("User not found");
      }

      const bunks = userDocSnap.data().bunks || [];

      const formattedBunks = bunks.map((bunk, index) => ({
        label: `bunk ${bunk.bunkNumber}`,
        value: bunk.bunkNumber,
      }));

      return formattedBunks;
    } catch (error) {
      return rejectWithValue(error.message || "Failed to fetch Bunks");
    }
  }
);

export const fetchAccountsByName = createAsyncThunk(
  "fetchAccountsByName",
  async ({ selectedCampId, namePrefix }, { rejectWithValue }) => {
    try {
      if (!selectedCampId || !namePrefix) {
        return;
      }

      const accountsRef = collection(db, `users/${selectedCampId}/accounts`);

      const startAt = namePrefix;
      const endAt = namePrefix + "\uf8ff"; // Unicode high value to match all possible characters

      const startQuery = query(
        accountsRef,
        where("name", ">=", startAt),
        where("name", "<=", endAt)
      );

      const querySnapshot = await getDocs(startQuery);

      const accounts = [];
      querySnapshot.forEach((doc) => {
        accounts.push({
          label: doc.data().name,
          value: doc.id,
        });
      });

      return accounts;
    } catch (error) {
      return rejectWithValue(error.message || "Error fetching accounts");
    }
  }
);

export const fetchAccountDetails = createAsyncThunk(
  "fetchAccountDetails",
  async ({ userId, accountId }, { rejectWithValue }) => {
    try {
      if (!userId || !accountId) {
        throw new Error("Invalid account information");
      }

      const accountDocRef = doc(db, `users/${userId}/accounts/${accountId}`);
      const accountDocSnap = await getDoc(accountDocRef);

      if (!accountDocSnap.exists()) {
        throw new Error("Account not found");
      }

      const accountData = accountDocSnap.data();

      const totalSpent = accountData.orders
        ? accountData.orders
            .reduce((total, order) => {
              return total + (order.total || 0);
            }, 0)
            .toFixed(2)
        : "0.00";

      const accountDetails = {
        name: accountData.name,
        bunk: accountData.bunk || 1,
        currentBalance: accountData.balance,
        accountId: accountData.autoGenerateNo,
        totalSpent: totalSpent,
        id: accountDocSnap.id,
      };

      return accountDetails;
    } catch (error) {
      return rejectWithValue(
        error.message || "Failed to fetch account details"
      );
    }
  }
);

const initialState = {
  camps: [],
  bunks: [],
  camperNames: [],
  status: AccountStatus.IDLE,
  isLoading: false,
  camperDetails: {
    camp: "",
    bunk: "",
    name: "",
  },
  accountData: {},
  senderDetails: {
    amount: "",
    applicationFee: 0,
    totalPay: 0,
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    notifyLowBalance: true,
    additionalInformation: "",
    xBillStreet: "",
    xBillCity: "",
    xBillZip: "",
    xBillState: "",
    xBillCountry: "",
  },
  error: null,
};

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    setCamperDetails: (state, action) => {
      state.camperDetails = { ...state.camperDetails, ...action.payload };
    },
    setAccountStatus: (state, action) => {
      state.status = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setAccountData: (state, action) => {
      state.accountData = action.payload;
    },
    setSenderDetails: (state, action) => {
      state.senderDetails = { ...state.senderDetails, ...action.payload };
    },
    resetAccount: (state, action) => {
      const preservedCamps = state.camps;
      return { ...initialState, camps: preservedCamps };
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchCamps.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(fetchCamps.fulfilled, (state, action) => {
      state.isLoading = false;
      state.camps = action.payload;
    });
    builder.addCase(fetchCamps.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });

    builder.addCase(fetchBunks.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(fetchBunks.fulfilled, (state, action) => {
      state.isLoading = false;
      state.bunks = action.payload;
    });
    builder.addCase(fetchBunks.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });

    builder.addCase(fetchAccountsByName.pending, (state, action) => {
      state.isLoading = true;
      state.camperNames = [];
    });
    builder.addCase(fetchAccountsByName.fulfilled, (state, action) => {
      state.isLoading = false;
      state.camperNames = action.payload;
    });
    builder.addCase(fetchAccountsByName.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.camperNames = [];
    });

    builder.addCase(fetchAccountDetails.pending, (state, action) => {
      state.isLoading = true;
      state.error = null;
      state.status = AccountStatus.SEARCHING;
    });
    builder.addCase(fetchAccountDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.accountData = action.payload;
      state.status = AccountStatus.FOUND;
    });
    builder.addCase(fetchAccountDetails.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.status = AccountStatus.NOT_FOUND;
    });
  },
});

export const {
  setCamperDetails,
  setAccountStatus,
  setLoading,
  setAccountData,
  setSenderDetails,
  resetAccount,
  setError,
} = accountSlice.actions;

export default accountSlice.reducer;
