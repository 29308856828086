import React from "react";

const InputField = ({
  label,
  placeholder,
  id,
  value,
  onChange,
  type = "text",
  notRequired,
  labelColor,
  disabled = false,
  error,
}) => {
  return (
    <div className={`space-y-[3px] ${disabled ? "item-disabled" : ""}`}>
      <label
        htmlFor={id}
        className={`${!notRequired && "required-input"} ${
          labelColor ? `${labelColor} uppercase` : "text-primary"
        }`}
        style={{
          fontFamily: "Poppins",
          fontWeight: 500,
          fontSize: "10px",
          lineHeight: "15px",
          paddingLeft: "20px",
        }}
      >
        {label}
      </label>
      <div>
        <input
          id={id}
          value={value}
          type={type}
          placeholder={placeholder}
          className={`w-full h-[33px] pl-[20px] p-4 rounded-lg bg-inputBackground border border-inputBackground focus:outline-none focus:border-primary focus:ring-primary text-[14px] font-normal ${
            type === "number" && "number-input"
          } ${disabled ? "cursor-not-allowed" : "cursor-text"}`}
          onChange={onChange}
          disabled={disabled}
          onWheel={(e) => e.target.blur()} 
        />
      </div>

      {error && (
        <p
          style={{
            color: "#D22727",
            fontWeight: "400",
            fontSize: "11px",
            lineHeight: "16.5px",
          }}
        >
          {error}
        </p>
      )}
    </div>
  );
};

export default InputField;
