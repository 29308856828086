import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TiArrowLeft } from "react-icons/ti";
import LabelValue from "../common/LabelValue";
import InputField from "../common/InputField";
import TextArea from "../common/TextArea";
import Button from "../common/Button";
import AmountSelector from "../common/AmountSelector";
import {
  setAccountStatus,
  setError,
  setLoading,
  setSenderDetails,
} from "../../redux/accounts/accountSlice";
import { AccountStatus } from "../../data";
import axios from "../../api/axios";
import { render } from "@react-email/components";
import Email from "../../templates/Email";
import {
  capitalizeWords,
  findCampById,
  formatBalance,
  getTodayDate,
} from "../../utils";
import PaymentForm from "../cardknox/PaymentForm";
import { setGatewayResponse } from "../../redux/cards/cardSlice";
import GoogleAPIAddressField from "../common/GoogleAPIAddressField";
import sola from "../../assets/images/sola.png";
import GooglePayCheckout from "../cardknox/GooglePayCheckout";
import { storeTransaction } from "../../api/cardknox";

const AccountFound = () => {
  const dispatch = useDispatch();
  const { camperDetails, camps, accountData, senderDetails } = useSelector(
    (state) => state.account
  );
  const { cardInfo, gatewayResponse } = useSelector((state) => state.card);
  const [formErrors, setFormErrors] = useState({});

  const validate = (senderDetails, cardInfo) => {
    const errors = {};
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    if (!senderDetails.email) {
      errors.email = "Email is required";
    } else if (!regex.test(senderDetails.email)) {
      errors.email = "Invalid email address";
    }

    if (!senderDetails.firstName) {
      errors.firstName = "First name is required";
    }

    if (!senderDetails.lastName) {
      errors.lastName = "Last name is required";
    }

    if (!senderDetails.phone) {
      errors.phone = "Phone number is required";
    }

    if (!senderDetails.xBillStreet) {
      errors.street = "Street address is required";
    }

    if (!senderDetails.xBillCity) {
      errors.city = "City is required";
    }

    if (!senderDetails.xBillZip) {
      errors.zip = "ZIP code is required";
    }

    if (!senderDetails.xBillState) {
      errors.state = "State is required";
    }

    if (!senderDetails.amount) {
      errors.amount = "Please select an amount";
    }

    if (!cardInfo.cardToken) {
      errors.cardToken = "Your card number is invalid.";
    }

    if (!cardInfo.cvvToken) {
      errors.cvvToken = "Invalid CVV.";
    }

    if (!cardInfo.expiry || cardInfo.expiry.length !== 4) {
      errors.expiry = "Invalid expiry date.";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const onInputChange = (e) => {
    const { id, value } = e.target;
    dispatch(setSenderDetails({ [id]: value }));
  };

  const submitToGateway = async () => {
    try {
      let request = {
        xSoftwareName: process.env.REACT_APP_SOFTWARE_NAME,
        xSoftwareVersion: process.env.REACT_APP_SOFTWARE_VERSION,
        xVersion: process.env.REACT_APP_VERSION,
        xCommand: process.env.REACT_APP_COMMAND,
        xAmount: senderDetails.totalPay,
        xCardnum: cardInfo.cardToken,
        xBillFirstName: senderDetails.firstName,
        xBillLastName: senderDetails.lastName,
        xBillStreet: senderDetails.xBillStreet,
        xBillCity: senderDetails.xBillCity,
        xBillZip: senderDetails.xBillZip,
        xBillState: senderDetails.xBillState,
        xBillMobile: senderDetails.phone,
        xEmail: senderDetails.email,
        xExp: cardInfo.expiry,
        xCvv: cardInfo.cvvToken,
      };

      const response = await axios.post("/api/payment/gatewayjson", request);

      const responseBody = await response.data.data;

      dispatch(setGatewayResponse(responseBody));

      if (responseBody.xResult === "V") {
        window.ck3DS.verifyTrans(responseBody);
      }

      await storeTransaction(
        responseBody,
        senderDetails,
        camperDetails.camp,
        accountData.id
      );

      if (responseBody.xResult === "A") {
        try {
          const emailHtml = await render(
            <Email
              name={senderDetails.firstName}
              camperName={capitalizeWords(accountData?.name)}
              amount={senderDetails.amount}
              date={getTodayDate()}
              method="card"
              managerName=""
              managerEmail=""
              managerPhoneNo=""
            />
          );

          const emailData = {
            emailHtml,
            recipientEmail: senderDetails.email,
            recipientName: `${senderDetails.firstName} ${senderDetails.lastName}`,
            camperName: `${capitalizeWords(accountData?.name)}`,
          };

          await axios.post("/api/checkout", {
            emailData,
          });
        } catch (error) {
          dispatch(
            setError(
              error?.message || "An error occurred during mail checkout."
            )
          );
        } finally {
          dispatch(setAccountStatus(AccountStatus.SUCCESS));
        }
      }

      // clearCard();
      // clearCvv();
      // setExpiry("");
    } catch (error) {
      console.error("Error in submitting to the payment gateway", error);
      dispatch(setGatewayResponse(error));
      throw error;
    }
  };

  const handleCheckout = async () => {
    if (!validate(senderDetails, cardInfo)) {
      return;
    }

    try {
      dispatch(setLoading(true));

      await submitToGateway();
    } catch (error) {
      console.error("Error while checkout : ", error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const onBackButtonClick = () => {
    dispatch(setAccountStatus(AccountStatus.IDLE));
  };

  return (
    <div className="flex flex-col items-center w-full h-full">
      <div className="relative w-full px-5 sm:px-[60px] flex items-center justify-center">
        <div
          className="absolute left-5 sm:left-8 flex items-center gap-[8px] cursor-pointer"
          onClick={onBackButtonClick}
        >
          <TiArrowLeft size={20} />
          <h6 className="hidden sm:block text-[#12141E]">Back</h6>
        </div>
        <h2>Add Cash To Account</h2>
      </div>

      <div className="w-full flex items-center justify-center bg-backgroundGray p-[20px] sm:p-[30px] mt-[20px] sm:mt-[40px]">
        <div
          className="sm:max-w-[80%] flex flex-col gap-[20px]"
          style={{
            width: "100%",
          }}
        >
          <h6 className="text-[15px] sm:text-[25px] font-semibold text-primary text-start w-full">
            Account
          </h6>

          <div className="flex flex-col gap-[8px] sm:gap-0 sm:flex-row justify-between w-full">
            <div className="flex flex-col gap-[8px] w-[100%] sm:w-[50%]">
              <LabelValue label="Name" value={accountData?.name} />
              <LabelValue
                label="Camp"
                value={findCampById(camps, camperDetails.camp)}
              />
            </div>

            <div className="flex flex-row justify-between w-[100%] sm:w-[50%]">
              <div className="flex flex-col gap-[8px]">
                <LabelValue label="Bunk" value={accountData?.bunk} />
                <LabelValue label="Account ID" value={accountData.accountId} />
              </div>

              <div className="flex flex-col gap-[8px]">
                <LabelValue
                  label="Current Balance"
                  value={formatBalance(accountData.currentBalance)}
                />
                <LabelValue
                  label="Total Spent"
                  value={accountData.totalSpent}
                  haveDollar={false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col mt-[20px] sm:mt-[40px] px-[20px] sm:px-[60px] w-full">
        <h6 className="text-[15px] sm:text-[25px] font-semibold text-primary text-start w-full">
          Choose Your Amount
        </h6>

        <div className="bg-backgroundGray w-full mt-[20px] p-2 flex items-center justify-start">
          <AmountSelector />
        </div>
        {formErrors.amount && (
          <p
            style={{
              color: "#D22727",
              fontWeight: "400",
              fontSize: "11px",
              lineHeight: "16.5px",
            }}
          >
            {formErrors.amount}
          </p>
        )}

        <h6 className="font-medium text-[9px] sm:text-[10px] leading-[13.5px] sm:leading-[15px] text-[#535353] text-center sm:text-start mt-[7px]">
          *An additional 3% transaction fee will be applied to the total amount
        </h6>
      </div>

      <div className="flex flex-col mt-[20px] sm:mt-[40px] px-[20px] sm:px-[60px] w-full">
        <h6 className="text-[15px] sm:text-[25px] font-semibold text-primary text-start w-full">
          Contact
        </h6>

        <div className="flex flex-col sm:flex-row gap-5 justify-between w-full mt-[20px]">
          <div className="flex-grow">
            <InputField
              label="First Name"
              placeholder=""
              id="firstName"
              value={senderDetails.firstName}
              onChange={onInputChange}
              labelColor="#000000"
              error={formErrors.firstName}
            />
          </div>

          <div className="flex-grow">
            <InputField
              label="Last Name"
              placeholder=""
              id="lastName"
              value={senderDetails.lastName}
              onChange={onInputChange}
              labelColor="#000000"
              error={formErrors.lastName}
            />
          </div>
        </div>

        <div className="flex flex-col sm:flex-row gap-5 justify-between w-full mt-[20px]">
          <div className="flex-grow">
            <InputField
              label="Email"
              type="email"
              placeholder=""
              id="email"
              value={senderDetails.email}
              onChange={onInputChange}
              labelColor="#000000"
              error={formErrors.email}
            />
          </div>

          <div className="flex-grow">
            <InputField
              label="Phone"
              placeholder=""
              id="phone"
              value={senderDetails.phone}
              onChange={onInputChange}
              labelColor="#000000"
              type="number"
              error={formErrors.phone}
            />
          </div>
        </div>

        <div className="flex gap-[10px] items-center mt-4">
          <input
            type="checkbox"
            id="notifyLowBalance"
            onChange={(e) =>
              dispatch(setSenderDetails({ notifyLowBalance: e.target.checked }))
            }
            checked={senderDetails.notifyLowBalance}
          />
          <label
            htmlFor="notifyLowBalance"
            className="text-[9px] sm:text-[10px] text-[#12141E] leading-[13.5px] sm:leading-[15px] font-medium cursor-pointer"
          >
            Notify me when this account balance is low.
          </label>
        </div>

        <div className="w-full mt-[20px]">
          <TextArea
            label="Additional information"
            placeholder=""
            id="additionalInformation"
            value={senderDetails.additionalInformation}
            onChange={onInputChange}
            labelColor="#000000"
            notRequired
          />
        </div>
      </div>

      <div className="flex flex-col mt-[20px] sm:mt-[40px] px-[20px] sm:px-[60px] w-full">
        <div className="flex flex-row gap-[6px] items-end">
          <h6 className="text-[15px] sm:text-[25px] font-semibold text-primary text-start">
            Payment
          </h6>
          <h6 className="text-[9px] sm:text-[#11px] leading-[11.7px] sm:leading-[14.3px] text-[#000000]">
            Secured By
          </h6>
          <img
            src={sola}
            alt="sola"
            className="w-[33px] h-[9.05px] sm:w-[46.23px] sm:h-[12.68px] mb-[3px]"
          />
        </div>

        <div className="flex flex-col mt-[20px] h-[72px] justify-between text-[11px] sm:text-[13px] font-medium leading-[16.5px] sm:leading-[19.5px] text-[#12141E]">
          <span className="flex flex-row justify-between">
            <span>Add Money</span>
            <span>
              &#36;{senderDetails.amount ? senderDetails.amount : 0}.00
            </span>
          </span>

          <span className="flex flex-row justify-between">
            <span>4% transaction & application fee</span>
            <span>&#36;{senderDetails.applicationFee}.00</span>
          </span>

          <hr className="h-px border-1 text-inputBackground" />

          <span className="flex flex-row justify-between">
            <span>Total</span>
            <span>&#36;{senderDetails.totalPay}.00</span>
          </span>
        </div>

        <PaymentForm formErrors={formErrors} />

        <GoogleAPIAddressField
          label="billing address"
          id="address"
          labelColor="#000000"
          error={
            formErrors.street ||
            formErrors.city ||
            formErrors.state ||
            formErrors.zip
          }
        />

        <div className="w-full flex flex-row items-center justify-center mt-[19px]">
          <GooglePayCheckout />
        </div>

        <h6 className="mt-[16px] sm:mt-[12px] text-center text-[#000000] font-medium text-[8px] sm:text-[10px] leading-[12px] sm:leading-[15px]">
          You'll Receive a Receipt by Email after Order
        </h6>
      </div>
      <div className="mt-[11px] sm:mt-[17px]">
        <Button onClick={handleCheckout}>Confirm & Pay</Button>
      </div>

      {gatewayResponse && (
        <p
          style={{
            color: "#D22727",
            fontWeight: "400",
            fontSize: "18px",
            lineHeight: "16.5px",
            marginTop: "15px",
          }}
        >
          {gatewayResponse?.xError}
        </p>
      )}
    </div>
  );
};

export default AccountFound;
