export const getTodayDate = () => {
  const today = new Date().toLocaleDateString("en-us", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });

  return today;
};

export const findCampById = (camps, id) => {
  const camp = camps.find((camp) => camp.value === id);
  return camp
    ? camp.label.replace(/\b\w/g, (char) => char.toUpperCase())
    : null;
};

export const capitalizeWords = (str) => {
  return str.replace(/\b\w/g, (char) => char.toUpperCase());
};

export const formatBalance = (balance) => {
  if (balance === undefined) {
    return;
  }
  return balance < 0 ? `-$${Math.abs(balance)}` : `$${balance}`;
};
